<template>
  <section id="presentation" class="lg:pb-36 relative overflow-hidden">
    <div class="block w-full px-4 lg:px-0 2xl:max-w-7xl xl:max-w-6xl mx-auto pt-24 md:pt-36 lg:pt-48">
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-3xl lg:text-4xl gradient-clip" >{{$t('pages.home.hero.creative-services.title')}} <span>{{$t('pages.home.hero.creative-services.title-word')}}</span></h2>
        <p class="my-10 leading-8 px-2" >{{$t('pages.home.hero.creative-services.description')}}</p>
        <glow-button :to="localePath('/contact')">
          {{ $t('pages.home.hero.get-started') }}
        </glow-button>
      </div>
    </div>
    <div class="overflow-hidden absolute w-full h-full pointer-events-none top-0">
      <div class="pink-radial absolute"></div>
    </div>

  <LazySectionsHomeHowWeHelp />

  </section>
</template>
<script>
import glowButton from "../../blocks/glow-button/glow-button";

export default {
  components: {'glow-button': glowButton},
}
</script>
